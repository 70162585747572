import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import Img from 'gatsby-image'


const Cath = () => (
    <Layout>
        <StaticQuery
            query={graphql`
        {
    wordpressPage(title: {eq: "Cath"}) {
        id
        title
        featured_media {
        localFile {
            childImageSharp {
                        fluid(maxWidth: 200, maxHeight: 200) {
                        ...GatsbyImageSharpFluid
                        }
                    }
        }
        }
    }
    }
`}
            render={
                data => (
                    <div className="about-us">
                        <h2 className="major">Cath</h2>
                        <div className="row ">
                            <div className="about-us-photo col-5 mx-auto mt-0 mb-3">
                                <Img className="rounded-circle"
                                    fluid={data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
                                    alt="A photo of Cath"
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 my-auto ">
                                <ul>
                                    <li>20+ years experience in IT</li>
                                    <li>Creative problem-solver</li>
                                    <li>BSc, BEng, MSc, MEd</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
        />
    </Layout>
)

export default Cath
