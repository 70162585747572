import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import Img from 'gatsby-image'


const Calum = () => (
  <Layout>
      <StaticQuery
      query={graphql`
        {
    wordpressPage(title: {eq: "Calum"}) {
      id
      title
      featured_media {
        localFile {
          childImageSharp {
                      fluid(maxWidth: 200, maxHeight: 200) {
                        ...GatsbyImageSharpFluid
                      }
                    }
        }
      }
    }
  }
`}
        render={
          data => (
            <div className="about-us">
              <h2 className="major">Calum</h2>
              <div className="row ">
                <div className="about-us-photo col-5 mx-auto mt-0 mb-3">
                  <Img className="rounded-circle"
                  fluid={data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
                  alt="A photo of Calum"
                  />
                </div>
                <div className="col-sm-12 col-md-6 my-auto ">
                  <ul>
                    <li>CodeClan Graduate</li>
                    <li>PDA in Software Engineering</li>
                    <li>Background in Art and Design</li>
                  </ul>
                </div>
              </div>
            </div>
        )}
      />
  </Layout>
  )
  
  export default Calum
