import React from 'react';

const Menu = props => (

  <div
    aria-haspopup= "true"
    className="icon"
    tabIndex="0">
    Menu
    <ul role= "menu"  className="menu">
      <li role="menu-item" className="spread">
        <a
          onClick={() => {
            props.onOpenArticle('services')
          }}
          className="unit"
          tabIndex="0"
          onKeyDown={() => {
            if (event.keyCode == 13)
              props.onOpenArticle('services')
          }}
          


        >
    
          Services
        </a>
      </li>

      <li role="menu-item" className="spread">
        <a
          onClick={() => {
            props.onOpenArticle('contact')
          }}
          className="unit"
          tabIndex="0"
          onKeyDown={() => {
            if (event.keyCode == 13)
              props.onOpenArticle('contact')
          }}
        >
          Contact
        </a>
      </li>

      <li role="menu-item" className="spread">
        <a
          onClick={() => {
            props.onOpenArticle('portfolio')
          }}
          className="unit"
          tabIndex="0"
          onKeyDown={() => {
            if (event.keyCode == 13)
              props.onOpenArticle('portfolio')
          }}
        >
          Portfolio
        </a>
      </li>

      <li role="menu-item" className="spread">
        <a
          onClick={() => {
            props.onOpenArticle('blog')
          }}
          className="unit"
          tabIndex="0"
          onKeyDown={() => {
            if (event.keyCode == 13)
              props.onOpenArticle('blog')
          }}
        >
          Articles
        </a>
      </li>
      <li role="menu-item" className="spread">
        <a
          onClick={() => {
            props.onOpenArticle('about')
          }}
          className="unit"
          tabIndex="0"
          onKeyDown={() => {
            if (event.keyCode == 13)
              props.onOpenArticle('about')
          }}    
        >
          About
        </a>
      </li>
    </ul>
  </div>
)
export default Menu;