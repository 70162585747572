import PropTypes from 'prop-types'
import React from 'react'
import Menu from '../components/Menu'
import { FaReact } from 'react-icons/fa'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span >< Menu onOpenArticle={props.onOpenArticle} /></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Nanoweb</h1>
        <div>
          <FaReact />
        </div>
        <p>
          A Scottish web development company experienced in supporting small businesses.
        </p>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
