import PropTypes from 'prop-types'
import React from 'react'
import { FaCaretRight} from 'react-icons/fa'
import { AiOutlineMail} from 'react-icons/ai'
import { AiOutlinePhone } from 'react-icons/ai'
import Portfolio from '../pages/portfolio'
import Calum from '../components/Calum'
import Cath from '../components/Cath'
import Blog from '../pages/blog'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyDown={() => {
          if (event.keyCode == 13 ||37||27)
            this.props.onCloseArticle()
        }}
        tabIndex="0"
        >

      </div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
        
      >
        <article
          id="services"
          className={`${this.props.article === 'services' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Our Services</h2>
          <span className="image main"></span>
          <ul>
            <li>
              <FaCaretRight className="service-icon" /> Cloud based hosting
            </li>
            <li>
              <FaCaretRight className="service-icon" /> Email services
            </li>
            <li>
              <FaCaretRight className="service-icon" /> Domain registration
            </li>
            <li>
              <FaCaretRight className="service-icon" /> Website design/ UX / UI{' '}
            </li>
            <li>
              <FaCaretRight className="service-icon" /> Web app building and
              coding
            </li>
            <li>
              <FaCaretRight className="service-icon" /> Website modernisation{' '}
            </li>
            <li>
              <FaCaretRight className="service-icon" /> Optimising
              responsiveness and speed
            </li>
            <li>
              <FaCaretRight className="service-icon" /> Troubleshooting and
              crisis support
            </li>
          </ul>
          <div>
            <h2 className="major">Technologies </h2>
            <p>
              HTML, CSS, Javascript, Ruby, PHP.
              <br />
              Wordpress, Drupal, React, Vue, Rails, LAMP stack, MERN stack
            </p>
          </div>
          <p>
            We design and code websites and offer support for people managing
            their own Wordpress sites.
          </p>

          {close}
        </article>

        <article
          id="workportfolio"
          className={`${this.props.article === 'portfolio' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major" id="portfolio-title">
            Portfolio
          </h2>
          <Portfolio />
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major" >About</h2>
          <p>
            We are a fully insured, experienced web development company with the
            ability to code rather than churn out templated sites. We are a team
            of two based in the North East of Scotland.
          </p>
          <div className="list-box">
            <Cath></Cath>
          </div>

          <div className="list-box">
            <Calum></Calum>
          </div>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <div className="btn">
            <a href="mailto:admin@nanoweb.co.uk" className="contact">
              <AiOutlineMail></AiOutlineMail>
            </a>
            <a href="mailto:admin@nanoweb.co.uk" className="">
              Email us at admin@nanoweb.co.uk
            </a>
          </div>
          {/* <div className="phone">
            <AiOutlinePhone></AiOutlinePhone>
            <span id="words">Or Phone us on ......</span>
          </div> */}
          <hr />
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/Nanoweb_Tech"
                className="icon fa-twitter"
                id="twitter-icon"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/nanowebscot/"
                className="icon fa-facebook"
                id="facebook-icon"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/nanoweb_technologies/"
                className="icon fa-instagram"
                id="instagram-icon"
              >
                <span className="label">Instagram</span>
              </a>
            </li>{' '}
          </ul>
          {close}
        </article>
        <article
          id="blog"
          className={`${this.props.article === 'blog' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major" id="articles-title">Articles</h2>
          <Blog />
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
